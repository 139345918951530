import "./index.scss"

import React, { useState, useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../plugins/common"
import HomepageHeader from "../components/homepageV2/Header"
import Steps from "../components/referral/Steps"
import TitleContent from "../components/referral/TitleContent"
import ComparisonTable from "../components/homepageV2/ComparisonTable"
const Resources = loadable(() =>
  import("../components/homepageV2/ResourcesClean")
)
const ItemBox = loadable(() => import("../components/homepageV2/ItemBox"))
const IconTextBanner = loadable(() =>
  import("../components/homepageV2/IconTextBanner")
)
const CustomerReview = loadable(() =>
  import("../components/homepageV2/CustomerReview")
)

const IndexPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext

  const keyLabels = general.allStrapiRessource.nodes[0]
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.header.image[0])
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  const aleaToken = general.aleaToken
  const referee = data.referee
  const stepData = { title: referee.stepTitle, step: referee.steps }
  const comparisonTables = general.allStrapiComparisons?.nodes

  const [referral, setReferral] = useState(null)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  data.meta.organizationSchema = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    name: site.siteMetadata.title,
    url: site.siteMetadata.siteUrl,
    logo: getBackgroundImage(general.allStrapiGeneral.nodes[0].logo),
    sameAs: [
      "https://www.linkedin.com/company/alea-health-insurance/",
      "https://www.facebook.com/Aleacarehk",
    ],
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params) {
      const seed = params.get("ci")
      const referralCode = params.get("referrer")

      if (seed && referralCode) {
        const referralData = {
          seed: seed,
          code: referralCode,
        }
        setReferral(referralData)
        // const referrerEmail = decrypt(referCode, seed)
        // console.log("referrerEmail: ", referrerEmail, " referCode: ", referCode)
      }
    }
  }, [])
  // console.log("location: ", location, "params: ", params)

  const getComparisonTable = id => {
    if (comparisonTables && comparisonTables.length) {
      return comparisonTables.find(com => com.strapiId === id)
    }
    return null
  }

  // console.log("data: ", data)
  // console.log("general: ", general)
  return (
    <Layout
      props={general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      lang={lang}
      location={location}
      ogImage={ogImage}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="overflow-hidden index">
        <HomepageHeader
          data={data.header}
          referee={referee}
          referralData={referral}
          token={aleaToken}
          location={location}
          lang={lang}
        />
        {referral && (
          <div className={`bodyMargin`}>
            <Steps data={stepData} />
          </div>
        )}
        {data.dynamicBody?.map((section, index) => {
          if (section.strapi_component === "homepage.horizontal-items")
            return <ItemBox key={index} lang={lang} data={section} />
          if (section.strapi_component === "homepage.customer-review")
            return <CustomerReview key={index} lang={lang} data={section} />
          if (section.strapi_component === "homepage.horizontal-cta")
            return <IconTextBanner key={index} lang={lang} data={section} />
          if (section.strapi_component === "homepage.comparison-table")
            return (
              <ComparisonTable
                key={index}
                lang={lang}
                data={getComparisonTable(section.comparison.id)}
              />
            )
          return <></>
        })}

        {referral && (
          <div className={`bodyMargin`}>
            <TitleContent data={referee.termsAndCondition} smallTitle={true} />
          </div>
        )}
        <Resources
          props={data.resources}
          categories={pageContext.categories}
          labels={keyLabels}
          lang={lang}
        />
      </div>
    </Layout>
  )
}

export default IndexPage
