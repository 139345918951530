// extracted by mini-css-extract-plugin
export var BottomToTop = "Header-module--BottomToTop--83d5f";
export var alertRed = "Header-module--alert-red--7e7e5";
export var animateWords = "Header-module--animateWords--857c1";
export var bgBarGrey = "Header-module--bg-bar-grey--9fd51";
export var bgBeige = "Header-module--bg-beige--39e01";
export var bgBeige2 = "Header-module--bg-beige2--2ff9a";
export var bgCopper = "Header-module--bg-copper--f166f";
export var bgDaffodil = "Header-module--bg-daffodil--c63e6";
export var bgGreyText = "Header-module--bg-grey-text--bc9ee";
export var bgMing = "Header-module--bg-ming--0ca64";
export var bgMint = "Header-module--bg-mint--7c839";
export var bgRed = "Header-module--bg-red--8cc70";
export var bgRedTrans = "Header-module--bg-red-trans--e3a66";
export var bgSand = "Header-module--bg-sand--ab421";
export var bgSoftSun = "Header-module--bg-softSun--e7565";
export var bgTeal = "Header-module--bg-teal--c3b78";
export var bgWhite = "Header-module--bg-white--57ff3";
export var bgWomenSharp = "Header-module--bg-women-sharp--33a18";
export var blackText = "Header-module--black-text--9d296";
export var borderMing = "Header-module--border-ming--3da1c";
export var chinese = "Header-module--chinese--d6397";
export var copper = "Header-module--copper--4ec75";
export var corners = "Header-module--corners--3b57d";
export var cursorNormal = "Header-module--cursor-normal--92bee";
export var cursorPointer = "Header-module--cursor-pointer--e76b5";
export var customMarkdown = "Header-module--customMarkdown--e6c75";
export var dUnset = "Header-module--d-unset--bc19b";
export var darkGrey = "Header-module--dark-grey--11ff2";
export var dash = "Header-module--dash--4b131";
export var dashBig = "Header-module--dashBig--4099f";
export var deadSalmon = "Header-module--dead-salmon--22ec8";
export var errorField = "Header-module--errorField--75294";
export var flex = "Header-module--flex--0848b";
export var flex1 = "Header-module--flex-1--5016e";
export var fontSize12 = "Header-module--font-size-12--a80dc";
export var fontSize20 = "Header-module--font-size-20--e8229";
export var form = "Header-module--form--d6381";
export var formWrapper = "Header-module--formWrapper--de8f7";
export var fr = "Header-module--fr--8cd75";
export var greyPlaceholder = "Header-module--grey-placeholder--2946d";
export var greyText = "Header-module--grey-text--b321d";
export var h1sizing = "Header-module--h1sizing--845fa";
export var h2sizing = "Header-module--h2sizing--8efea";
export var header = "Header-module--header--08a4e";
export var hidden = "Header-module--hidden--27b38";
export var image = "Header-module--image--cc429";
export var imageAni = "Header-module--imageAni--80cd0";
export var imageZoom = "Header-module--imageZoom--1bdfe";
export var imageZoomGatsby = "Header-module--imageZoomGatsby--64120";
export var italic = "Header-module--italic--2adb2";
export var label = "Header-module--label--8d61b";
export var lightGrey = "Header-module--light-grey--8bae2";
export var logo = "Header-module--logo--466ff";
export var lora = "Header-module--lora--d98f2";
export var loraBold = "Header-module--lora-Bold--71142";
export var loraBoldItalic = "Header-module--lora-BoldItalic--859f0";
export var loraMedium = "Header-module--lora-Medium--5f9f2";
export var loraSemiBold = "Header-module--lora-SemiBold--0e5e0";
export var main = "Header-module--main--0b47b";
export var ming = "Header-module--ming--fbeee";
export var mingHover2 = "Header-module--ming-hover-2--8647f";
export var modalOpen = "Header-module--modal-open--91682";
export var noUnderline = "Header-module--no-underline--7f751";
export var openSans = "Header-module--openSans--a53d1";
export var openSansBold = "Header-module--openSans-Bold--75a34";
export var openSansSemiBold = "Header-module--openSans-SemiBold--22586";
export var pageGuide = "Header-module--page-guide--8a2aa";
export var popUp = "Header-module--popUp--52191";
export var popUpAni = "Header-module--pop-up-ani--14326";
export var quotation = "Header-module--quotation--5c7f9";
export var rightCopper = "Header-module--rightCopper--1b46b";
export var rightLine = "Header-module--rightLine--cab03";
export var round = "Header-module--round--2480b";
export var roundCorners = "Header-module--round-corners--cbe73";
export var row = "Header-module--row--b466d";
export var selectedType = "Header-module--selectedType--388a7";
export var shapes = "Header-module--shapes--386ee";
export var slogan = "Header-module--slogan--a0b85";
export var softCorners = "Header-module--soft-corners--358e8";
export var softShadows = "Header-module--soft-shadows--3670e";
export var softerCorners = "Header-module--softer-corners--8d191";
export var softyCorners = "Header-module--softy-corners--1a7f2";
export var submitBtn = "Header-module--submitBtn--b9d49";
export var subtitle = "Header-module--subtitle--f1afb";
export var tableShadow = "Header-module--table-shadow--12f36";
export var teal = "Header-module--teal--3e304";
export var topNavShadow = "Header-module--top-nav-shadow--abaf7";
export var topicArea = "Header-module--topicArea--0e680";
export var type = "Header-module--type--d190b";
export var uppercase = "Header-module--uppercase--cb385";
export var wFull = "Header-module--w-full--0bc3f";
export var white = "Header-module--white--540e9";
export var womenRed = "Header-module--women-red--95653";
export var womenSharp = "Header-module--women-sharp--1bff3";
export var word = "Header-module--word--33ec7";