import React from "react"
import * as style from "./Steps.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImage } from "../../plugins/common"

const Steps = ({ data }) => {
  const { title, step } = data
  return (
    <div className={`my-5 ${style.steps}`}>
      <h2 className={`ming`}>{title}</h2>
      <div className={`flex flex-lg-row flex-column mt-5`}>
        {step.map((s, index) => (
          <div key={index} className={`flex-1 flex p-1 mb-lg-0 mb-4`}>
            <div className={``}>
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className={`${style.image} me-3`}
                image={getGatsbyImage(s.image)}
                alt="alea"
              />
            </div>
            <span className={`ming align-self-center`}>{s.title}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Steps
